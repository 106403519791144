import * as Icon from 'react-feather';
import classnames from 'classnames';
import React, {useCallback, useEffect, useState, useRef} from 'react';
import domAlign from 'dom-align';

import './Popover.css';

export const Body = ({className, children}) => (
  <div className={classnames('px-4 pt-4', className)}>{children}</div>
);

export const Header = ({color = '#F3C177', icon, title, subtitle}) => {
  const IconComponent = Icon[icon];

  return (
    <div className="flex pb-3 px-4 border-b border-solid border-gray-100">
      <IconComponent size={28} color={color} />
      <div className="flex flex-col ml-4">
        <span className="block font-semibold text-sm">{title}</span>
        <span className="text-xs text-gray">{subtitle}</span>
      </div>
    </div>
  );
};

export const Popover = ({className, children, trigger}) => {
  const [visible, setVisible] = useState(false);

  const popoverRef = useRef(null);

  const onHover = useCallback(event => {
    setVisible(true);
  }, []);

  const onHide = useCallback(event => {
    setVisible(false);
  }, []);

  const onToggle = useCallback(event => {
    setVisible(prev => !prev);
  }, []);

  useEffect(() => {
    const triggerElement = document.querySelector(`#${trigger}`);

    if (visible && trigger && popoverRef.current) {
      domAlign(popoverRef.current, triggerElement, {
        offset: [0, -20],
        overflow: {adjustX: true, adjustY: true},
        points: ['bc', 'tc'],
      });
    }
  }, [popoverRef, trigger, visible]);

  useEffect(() => {
    const element = document.querySelector(`#${trigger}`);

    if (!element) {
      return;
    }

    element.addEventListener('mouseenter', onHover, true);
    element.addEventListener('mouseleave', onHide, true);
    element.addEventListener('click', onToggle, true);
    element.addEventListener('touchend', onToggle, true);

    return () => {
      element.removeEventListener('mouseenter', onHover, true);
      element.removeEventListener('mouseleave', onHide, true);
      element.removeEventListener('click', onToggle, true);
      element.removeEventListener('touchend', onToggle, true);
    };
  }, [trigger, onHover, onHide, onToggle]);

  return (
    <div
      className={classnames(
        'popover absolute bg-white py-4 rounded-md popover-shadow',
        className,
        {
          hidden: !visible,
        }
      )}
      ref={popoverRef}
    >
      {children}
    </div>
  );
};

Popover.Header = Header;
Popover.Body = Body;
