import {
  Activity,
  ArrowRight,
  Cloud,
  Copy,
  DollarSign,
  GitPullRequest,
  Lock,
  RefreshCcw,
  Server,
  Shuffle,
  Users,
  Zap,
  GitHub,
} from 'react-feather';
import {Link} from 'gatsby';
import React from 'react';
import './index.css';

import {CardDiagram} from '../components/CardDiagram';
import {DesignedForDevelopers} from '../components/DesignedForDevelopers';
import {Hero} from '../components/Hero';
import CTA from '../components/CTA';
import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout spacingTop={false}>
    <SEO
      description="Fleet is Serverless Function technology for Node.js more efficient, run in few ms and scale compared to other platforms."
      title="Serverless Function for large scale APIs - Fleet"
    />
    <section
      className="relative mt-8 lg:mt-24 px-6 sm:px-0"
      id="request-early-access"
    >
      <Hero />
    </section>
    <section className="sm:mt-24 mb-16 px-6 sm:px-16 py-16 sm:py-24 relative">
      <div className="container">
        <div className="w-4/6">
          <span className="block font-medium text-primary text-lg mb-6">
            How it works
          </span>
          <h2 className="block font-bold text-4xl mb-6 leading-tight">
            The ultimate Serverless Function
          </h2>
          <p className="block text-lg leading-relaxed mb-8">
            Fleet Function runs serverless functions in a secure Node.js runtime
            to avoid cold start issues and accept multiple requests on the same
            instance. Teams can easily scale their APIs based on concurrency and
            asynchronous limits, lowering per-request costs.
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 mt-16">
          <div>
            <div className="flex bg-primary bg-opacity-25 rounded-md items-center justify-center w-10 h-10">
              <span className="text-primary font-semibold text-2xl">1</span>
            </div>
            <p className="font-semibold mt-4 mb-3">Deployment</p>
            <p className="font-medium mb-6">
              Make a deployment to the stage dev, a URL will be created
              automatically.
            </p>
          </div>
          <div>
            <div className="flex bg-primary bg-opacity-25 rounded-md items-center justify-center w-10 h-10">
              <span className="text-primary font-semibold text-2xl">2</span>
            </div>
            <p className="font-semibold mt-4 mb-3">Promote Production</p>
            <p className="font-medium mb-6">
              Confidently promote stage deployment to production using rules
              like canary deployment.
            </p>
          </div>
          <div>
            <div className="flex bg-primary bg-opacity-25 rounded-md items-center justify-center w-10 h-10">
              <span className="text-primary font-semibold text-2xl">3</span>
            </div>
            <p className="font-semibold mt-4 mb-3">Request fastest</p>
            <p className="font-medium mb-6">
              Request functions via HTTP and running with near-zero cold starts,
              built on top of Node.js and secure.
            </p>
          </div>
          <div>
            <div className="flex bg-primary bg-opacity-25 rounded-md items-center justify-center w-10 h-10">
              <span className="text-primary font-semibold text-2xl">4</span>
            </div>
            <p className="font-semibold mt-4 mb-3">Large Scale</p>
            <p className="font-medium mb-6">
              Functions run as a Node.js environment, scaled asynchronously and
              concurrently.
            </p>
          </div>
        </div>
        <div className="mt-16">
          <CardDiagram />
        </div>
        <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-16 mt-24">
          <li className="flex flex-col">
            <div className="flex-shrink-0 box bg-black flex items-center justify-center">
              <Server size={24} color="#fff" />
            </div>
            <div className="mt-8">
              <h4 className="mb-2 text-lg font-semibold">Zero server</h4>
              <p className="text-base">
                Don't worry about keeping a 24/7 server online.
              </p>
            </div>
          </li>
          <li className="flex flex-col">
            <div className="flex-shrink-0 box bg-black flex items-center justify-center">
              <Copy size={24} color="#fff" />
            </div>
            <div className="mt-8">
              <h4 className="mb-2 text-lg font-semibold">Auto-Scale</h4>
              <p className="text-base">Its functions are invoked to 0 and N.</p>
            </div>
          </li>
          <li className="flex flex-col">
            <div className="flex-shrink-0 box bg-black flex items-center justify-center">
              <DollarSign size={24} color="#fff" />
            </div>
            <div className="mt-8">
              <h4 className="mb-2 text-lg font-semibold">Pay for use</h4>
              <p className="text-base">
                Costs are made based on the resources you use.
              </p>
            </div>
          </li>
          <li className="flex flex-col">
            <div className="flex-shrink-0 box bg-black flex items-center justify-center">
              <Cloud size={24} color="#fff" />
            </div>
            <div className="mt-8">
              <h4 className="mb-2 text-lg font-semibold">Simplify DevOps</h4>
              <p className="text-base">
                There is no need to configure infrastructure or handle
                containers.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <DesignedForDevelopers className="mt-18 sm:mt-24 mb-16 lg:mb-24" />
    <section className="mt-16 lg:mt-56 mb-16 sm:mb-56 px-6">
      <div className="container">
        <span className="block font-medium text-lg mb-6">Why Fleet</span>
        <h2 className="block font-bold text-4xl leading-tight">
          Platform end-to-end
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 sm:gap-10">
          <div>
            <p className="text-lg leading-normal mt-6">
              Serverless function technology from end to end with the platform
              for deployments, metrics, workflow...
            </p>
            <ul className="mt-10">
              <li className="flex flex-col sm:flex-row mb-16">
                <div className="flex-shrink-0 box bg-black flex items-center justify-center">
                  <Activity size={24} color="#fff" />
                </div>
                <div className="sm:ml-8 sm:mt-0 mt-8">
                  <h3 className="mb-1 text-lg font-semibold">
                    Metrics built in
                  </h3>
                  <p className="text-lg">
                    Track usage, performance and errors on your functions.
                  </p>
                </div>
              </li>
              <li className="flex flex-col sm:flex-row mb-16">
                <div className="flex-shrink-0 box bg-black flex items-center justify-center">
                  <Lock size={24} color="#fff" />
                </div>
                <div className="sm:ml-8 sm:mt-0 mt-8">
                  <h3 className="mb-1 text-lg font-semibold">Secrets</h3>
                  <p className="text-lg">
                    Add sensitive environment variables that are only available
                    for your functions.
                  </p>
                </div>
              </li>
              <li className="flex flex-col sm:flex-row mb-16">
                <div className="flex-shrink-0 box bg-black flex items-center justify-center">
                  <Users size={24} color="#fff" />
                </div>
                <div className="sm:ml-8 sm:mt-0 mt-8">
                  <h3 className="mb-1 text-lg font-semibold">Team</h3>
                  <p className="text-lg">
                    Organize your team by projects to simplify workflows and
                    permissions.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="flex items-center justify-center">
            <img
              className="select-none sm:max-w-xl"
              alt="Platform for serverless functions"
              src="/images/platform-for-serverless-functions.svg"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="mt-16 lg:mt-40 mb-16 sm:mb-56 px-6">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-2 sm:gap-10">
          <div className="flex items-center justify-center sm:mt-0 mt-8">
            <img
              className="select-none sm:max-w-xl"
              alt="Fleet Function, fastest and safely"
              src="/images/preview-deployments.svg"
            />
          </div>
          <div>
            <h2 className="mt-6 text-2xl font-semibold">Preview deployments</h2>
            <p className="text-lg leading-normal mt-6">
              For each new deployment a new URL is assigned to your deployment
              for preview use.
            </p>
            <ul className="mt-10">
              <li className="flex flex-col sm:flex-row mb-16">
                <div className="flex-shrink-0 box bg-black flex items-center justify-center">
                  <GitHub size={24} color="#fff" />
                </div>
                <div className="sm:ml-8 sm:mt-0 mt-8">
                  <h3 className="mb-1 text-lg font-semibold">
                    Continuous Deployment
                  </h3>
                  <p className="text-lg">
                    Build your own deployment workflow using{' '}
                    <Link
                      className="text-primary"
                      to="/docs/continuous-deployment.html"
                    >
                      GitHub Actions
                    </Link>{' '}
                    to deploy to Fleet.
                  </p>
                </div>
              </li>
              <li className="flex flex-col sm:flex-row mb-16">
                <div className="flex-shrink-0 box bg-black flex items-center justify-center">
                  <GitPullRequest size={24} color="#fff" />
                </div>
                <div className="sm:ml-8 sm:mt-0 mt-8">
                  <h3 className="mb-1 text-lg font-semibold">
                    Deployment history
                  </h3>
                  <p className="text-lg">
                    With each new deployment, a url is created that can be used
                    to share with your team or serve as a test endpoint.
                  </p>
                </div>
              </li>
              <li className="flex flex-col sm:flex-row mb-16">
                <div className="flex-shrink-0 box bg-black flex items-center justify-center">
                  <RefreshCcw size={24} color="#fff" />
                </div>
                <div className="sm:ml-8 sm:mt-0 mt-8">
                  <h3 className="mb-1 text-lg font-semibold">
                    Quick Rollbacks
                  </h3>
                  <p className="text-lg">
                    Quickly rollback deployments with code failures with
                    immediate effect in production.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="mt-16 lg:mt-56 mb-16 sm:mb-56 px-6">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-2 sm:gap-10">
          <div>
            <div className="flex items-center">
              <img
                className="select-none w-10 h-10"
                alt="Fleet Traffic Shifting"
                src="/images/logo-fleet-ts.svg"
              />
              <h2 className="ml-3 text-2xl font-semibold leading-relaxed">
                Fleet Traffic Shifting
              </h2>
            </div>
            <p className="text-lg leading-normal mt-6">
              Critical microservices and APIs that meet a large scale of user
              demand is critical and cannot fail due to bugs in the code. The
              main adoption of mitigation is canary deployments.
            </p>
            <ul className="mt-10">
              <li className="flex flex-col sm:flex-row mb-16">
                <div className="flex-shrink-0 box bg-black flex items-center justify-center">
                  <Zap size={24} color="#fff" />
                </div>
                <div className="sm:ml-8 sm:mt-0 mt-8">
                  <h3 className="mb-1 text-lg font-semibold">
                    Automation rules
                  </h3>
                  <p className="text-lg">
                    Automate your canary implantations based on traffic data to
                    decrease monitoring and mitigate errors from expensive
                    deployments.
                  </p>
                </div>
              </li>
              <li className="flex flex-col sm:flex-row mb-16">
                <div className="flex-shrink-0 box bg-black flex items-center justify-center">
                  <Shuffle size={24} color="#fff" />
                </div>
                <div className="sm:ml-8 sm:mt-0 mt-8">
                  <h3 className="mb-1 text-lg font-semibold">
                    Manual traffic control
                  </h3>
                  <p className="text-lg">
                    Perform manual traffic control as you get more data.
                  </p>
                </div>
              </li>
              <li className="flex flex-col sm:flex-row mb-16">
                <div className="flex-shrink-0 box bg-black flex items-center justify-center">
                  <Activity size={24} color="#fff" />
                </div>
                <div className="sm:ml-8 sm:mt-0 mt-8">
                  <h3 className="mb-1 text-lg font-semibold">Monitoring</h3>
                  <p className="text-lg">
                    Monitor traffic to make the best decisions and adjust the
                    rules to your reality.
                  </p>
                </div>
              </li>
            </ul>
            <Link className="flex flex-row items-center" to="/traffic-shifting">
              <p className="font-medium">Learn more about Traffic Shifting</p>
              <ArrowRight
                className="ml-2"
                size={20}
                color="#000"
                strokeWidth={2}
              />
            </Link>
          </div>
          <div className="flex items-center justify-center sm:mt-0 mt-16">
            <img
              className="select-none sm:max-w-xl"
              alt="Fleet Traffic Shifting, flow of creating traffic change and adding rules"
              src="/images/fleet-traffic-shifting.svg"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="mt-16 lg:mt-56 sm:mb-40 mb-16 px-6">
      <div className="container">
        <div className="text-center">
          <h2 className="font-bold text-4xl mb-8">
            Create faster solutions with functions
          </h2>
          <p className="font-medium text-gray text-xl max-w-3xl inline-block eading-relaxed">
            Use the Fleet to build your critical services that require
            resilience, high demand, low cost and high speed with our
            technology.
          </p>
        </div>
        <ul className="mt-32 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-16">
          <li className="flex flex-col">
            <div className="rounded-md bg-black h-32 flex items-center justify-center">
              <img
                className="select-none max-w-xl"
                alt="JAMstack"
                src="/images/JAMstack.svg"
              />
            </div>
            <div className="mt-6">
              <h3 className="mb-2 text-xl font-semibold">JAMstack</h3>
              <p className="text-base text-gray">
                Enrich the JAMstack ecosystem using functions
              </p>
            </div>
          </li>
          <li className="flex flex-col">
            <div className="rounded-md bg-black h-32 flex items-center justify-center">
              <img
                className="select-none max-w-xl"
                alt="Microservices"
                src="/images/microservices.svg"
              />
            </div>
            <div className="mt-6">
              <h3 className="mb-2 text-xl font-semibold">Microservices</h3>
              <p className="text-base text-gray">
                Build your service fleet on serverless functions
              </p>
            </div>
          </li>
          <li className="flex flex-col">
            <div className="rounded-md bg-black h-32 flex items-center justify-center">
              <img
                className="select-none max-w-xl"
                alt="Micro APIs"
                src="/images/apis.svg"
              />
            </div>
            <div className="mt-6">
              <h3 className="mb-2 text-xl font-semibold">APIs</h3>
              <p className="text-base text-gray">
                Build your product's APIs on serverless functions
              </p>
            </div>
          </li>
          <li className="flex flex-col">
            <div className="rounded-md bg-black h-32 flex items-center justify-center">
              <img
                className="select-none max-w-xl"
                alt="Data Processing"
                src="/images/tasks.svg"
              />
            </div>
            <div className="mt-6">
              <h3 className="mb-2 text-xl font-semibold">Tasks</h3>
              <p className="text-base text-gray">
                Orchestrate background functions for data processing
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <CTA />
  </Layout>
);

export default IndexPage;
