import classnames from 'classnames';
import React, {useCallback, useState} from 'react';

import {useObserveRect} from '../hooks/useObserveRect';

export const Ping = ({className, triggerRef}) => {
  const [style, setStyle] = useState({});

  useObserveRect(
    useCallback(rect => {
      rect = rect ? rect : triggerRef.current.getBoundingClientRect();

      setStyle({
        height: `${rect.height / 1.6}px`,
        left: `${rect.left + (rect.width / 1.6) * 0.3}px`,
        top: `${rect.top + (rect.height / 1.6) * 0.3}px`,
        width: `${rect.width / 1.6}px`,
      });
    }, []),
    triggerRef
  );

  return (
    <div
      className={classnames('flex fixed h-10 w-10', className)}
      style={style}
    >
      <div className="animate-ping absolute inline-flex opacity-75 w-full h-full rounded-md bg-primary" />
    </div>
  );
};
