import {useCallback, useEffect, useRef} from 'react';

const rectAttrs = ['bottom', 'height', 'left', 'right', 'top', 'width'];

const DOMRectStub = {};

const rectChanged = (a, b = DOMRectStub) =>
  rectAttrs.some(prop => a[prop] !== b[prop]);

export const useObserveRect = (callback, node) => {
  const rafIdRef = useRef();

  const run = useCallback(
    (node, state) => {
      const newRect = node.getBoundingClientRect();

      if (rectChanged(newRect, state.rect)) {
        state.rect = newRect;

        callback(state.rect);
      }

      rafIdRef.current = window.requestAnimationFrame(() => run(node, state));
    },
    [callback]
  );

  useEffect(() => {
    if (node.current) {
      run(node.current, {
        hasRectChanged: false,
        rect: undefined,
      });

      return () => {
        if (rafIdRef.current) {
          cancelAnimationFrame(rafIdRef.current);
        }
      };
    }
  }, [node, run]);
};
